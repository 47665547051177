import React from "react";
import pdf from "../../../docs/uprise-api.pdf";
import Header from "../../../components/common/header.js";
import Footer from "../../../components/common/footer.js";
import styled from "styled-components";

function index() {
  const pdfPath = `${pdf}#toolbar=0&navpanes=0`;

  return (
    <IFrameContainer className="hide-scroll">
      {/* <Header hideMenus={true} /> */}
      <iframe
        src={"https://uprise-api-doc-prod-e07e097d0f7a.herokuapp.com"}
        id="api-doc-iframe"
      />
      {/* <Footer /> */}
    </IFrameContainer>
  );
}

export default index;

const IFrameContainer = styled.div`
  &.hide-scroll {
    height: 100vh;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  iframe {
    height: 100vh;
    width: 100%;
    border: none;
  }
`;
